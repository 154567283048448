import { _x, sprintf } from '@wordpress/i18n';

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const fortFields = document.querySelectorAll('.findstr-field-sort');

  if (findstr && 0 < fortFields.length) {
    findstr.hooks.addFilter(
      'findstrSortLabel',
      'ithqSort',
      (sortLabel, option, direction) => {
        let label = sortLabel;
        const directionLabel =
          direction === 'asc'
            ? _x('(ascendant)', 'Sort direction label', 'vtx')
            : _x('(descendant)', 'Sort direction label', 'vtx');

        switch (option) {
          case 'post_date':
          case 'event_end_date':
            label = sprintf(
              /* translators: %s: Sort direction label */
              _x('Date %s', 'Sort label', 'vtx'),
              directionLabel
            );
            break;
          case 'post_title':
            label = sprintf(
              /* translators: %s: Sort direction label */
              _x('Title %s', 'Sort label', 'vtx'),
              directionLabel
            );
            break;
          case 'public_job_start_date':
            label = sprintf(
              /* translators: %s: Sort direction label */
              _x('Publication date %s', 'Sort label', 'vtx'),
              directionLabel
            );
            break;
          case 'classified_ad_beginning_date':
            label = sprintf(
              /* translators: %s: Sort direction label */
              _x('Publication date %s', 'Sort label', 'vtx'),
              directionLabel
            );
            break;
          case 'company-category':
            label = sprintf(
              /* translators: %s: Sort direction label */
              _x('Category %s', 'Sort label', 'vtx'),
              directionLabel
            );
            break;
        }

        return label;
      }
    );
  }
});
