import domReady from '@wordpress/dom-ready';
import * as Focus from '../common/focus';

function openPopup(popup, mediaURL) {
  const popupID = popup.getAttribute('id');

  popup.classList.add('opened');
  popup.setAttribute('aria-hidden', 'false');
  document.documentElement.classList.add('lockedPosition');
  popup.querySelector('.popup__inner').focus();
  Focus.trapFocusInside(jQuery('#' + popupID));

  const openBtns = document.querySelectorAll(
    '.mediasGalleryOpenPopup[data-gallery-id="' + popupID + '"]'
  );

  if (0 < openBtns.length) {
    openBtns.forEach((openBtn) => {
      openBtn.setAttribute('aria-expanded', 'true');
    });
  }

  if (mediaURL) {
    const mediaElement = popup.querySelector('img[src="' + mediaURL + '"]');

    if (mediaElement) {
      setTimeout(() => {
        mediaElement.scrollIntoView();
      }, 100);
    }
  }
}

function closePopup(popup) {
  const popupID = popup.getAttribute('id');

  popup.classList.remove('opened');
  popup.setAttribute('aria-hidden', 'true');
  document.documentElement.classList.remove('lockedPosition');
  Focus.untrapFocusInside(jQuery('#' + popupID));

  const openBtns = document.querySelectorAll(
    '.mediasGalleryOpenPopup[data-gallery-id="' + popupID + '"]'
  );

  if (0 < openBtns.length) {
    openBtns.forEach((openBtn) => {
      openBtn.setAttribute('aria-expanded', 'false');
    });
  }
}

function initFakeCarousel(wrapper, wrapperChild) {
  wrapper.scrollTop = 0;
  wrapper.scrollLeft = 0;
  const nextBtn = wrapper
    .closest('.medias-gallery-block__gallery-component')
    .querySelector('.next');
  const prevBtn = wrapper
    .closest('.medias-gallery-block__gallery-component')
    .querySelector('.previous');

  let pos = { top: 0, left: 0, x: 0, y: 0 };

  const mouseDownHandler = function (e) {
    wrapper.style.cursor = 'grabbing';
    wrapper.style.userSelect = 'none';

    pos = {
      left: wrapper.scrollLeft,
      top: wrapper.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    wrapper.scrollTop = pos.top - dy;
    wrapper.scrollLeft = pos.left - dx;
  };

  const checkArrowsVisibility = function () {
    if (
      wrapper.scrollLeft + wrapperChild.clientWidth >
      wrapperChild.scrollWidth - 100
    ) {
      nextBtn.classList.add('disabled');
    } else {
      nextBtn.classList.remove('disabled');
    }

    if (wrapper.scrollLeft < 60) {
      prevBtn.classList.add('disabled');
    } else {
      prevBtn.classList.remove('disabled');
    }
  };

  const mouseUpHandler = function () {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    wrapper.style.cursor = 'grab';
    wrapper.style.removeProperty('user-select');

    checkArrowsVisibility();
  };

  if (wrapper.scrollLeft < 60) {
    prevBtn.classList.add('disabled');
  }

  nextBtn.addEventListener('click', function () {
    wrapper.scrollLeft = wrapper.scrollLeft + 300;
    checkArrowsVisibility();
  });

  prevBtn.addEventListener('click', function () {
    wrapper.scrollLeft = wrapper.scrollLeft - 300;
    checkArrowsVisibility();
  });

  if (wrapper.clientWidth < wrapperChild.scrollWidth) {
    wrapper.classList.add('draggable');
    wrapper.addEventListener('mousedown', mouseDownHandler);
  } else {
    prevBtn.classList.add('disabled');
    nextBtn.classList.add('disabled');
  }
}

domReady(() => {
  const popupBtnOpen = document.querySelectorAll('.mediasGalleryOpenPopup');
  const popupBtnClose = document.querySelectorAll('.mediasGalleryClosePopup');
  const carouselGalleries = document.querySelectorAll(
    '.medias-gallery-block.style-carousel'
  );
  const mediasGalleryBlocks = document.querySelectorAll(
    '.medias-gallery-block'
  );

  // init carousel style
  if (0 < carouselGalleries.length) {
    carouselGalleries.forEach(function (carousel) {
      const wrapper = carousel.querySelector(
        '.medias-gallery__gallery-wrapper'
      );
      const wrapperChild = carousel.querySelector('.medias-gallery__gallery');
      initFakeCarousel(wrapper, wrapperChild);
    });
  }

  // add medias to popup
  if (0 < mediasGalleryBlocks.length) {
    mediasGalleryBlocks.forEach(function (block) {
      const popupGallery = block.querySelector('.mainGallery');
      const medias = block.querySelectorAll('.gallery-item-block');
      const mediasCount = block.getAttribute('data-medias-count');

      if (
        0 < medias.length &&
        block.classList.contains('style-grid') &&
        0 < mediasCount
      ) {
        const lastMedia = medias[medias.length - 1];
        const element = document.createElement('p');
        element.classList.add('gallery-item__medias-count');
        element.textContent = '+ ' + mediasCount;
        lastMedia.append(element);
      }

      if (0 < medias.length && popupGallery) {
        medias.forEach(function (media) {
          const mediaType = media.getAttribute('data-media-type');
          const mediaURL = media.getAttribute('data-media-url');
          const mediaAlt = media.getAttribute('data-media-alt');
          const wrapperElement = document.createElement('div');
          wrapperElement.classList.add('medias-gallery__popup__gallery__item');

          if ('video' === mediaType) {
            const video = document.createElement('video');
            const videoSrc = document.createElement('source');
            videoSrc.setAttribute('src', mediaURL);
            video.setAttribute('autoPlay', '');
            video.setAttribute('loop', '');
            video.setAttribute('muted', '');
            video.append(videoSrc);
            wrapperElement.append(video);
          } else {
            const img = document.createElement('img');
            img.setAttribute('alt', mediaAlt);
            img.setAttribute('src', mediaURL);
            wrapperElement.append(img);
          }

          popupGallery.append(wrapperElement);
        });
      }
    });
  }

  // open popup
  if (0 < popupBtnOpen.length) {
    popupBtnOpen.forEach(function (btn) {
      btn.addEventListener('click', function () {
        const block = btn.closest('.medias-gallery-block');
        const popup = block.querySelector('.medias-gallery__popup-wrapper');
        const btnParent = btn.closest('.gallery-item-block');
        let imageURL = '';

        if (btnParent) {
          imageURL = btnParent.getAttribute('data-media-url');
        }

        openPopup(popup, imageURL);
      });
    });
  }

  // close popup
  if (0 < popupBtnClose.length) {
    popupBtnClose.forEach(function (btn) {
      btn.addEventListener('click', function () {
        const popup = btn.closest('.medias-gallery__popup-wrapper');
        closePopup(popup);
      });
    });
  }
});
