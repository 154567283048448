/* *
 * ========================================================================
 * Vortex Solution
 * ========================================================================
 * */

// Includes the custom Modernizr build based on the configurations set in .modernizrrc
require('Modernizr'); // eslint-disable-line import/no-extraneous-dependencies
require('nodelist-foreach-polyfill');
require('element-qsa-scope');
require('./main/dropdown-menu');
require('./main/mobile-menu');
require('./main/user-is-tabbing');
require('./main/forms');
require('./main/search-modal');
require('./main/breadcrumbs');
require('./main/popular-requests-carousel');
require('./main/promo-header-carousel');
require('./main/tooltip');
require('./main/icon-buttons');
require('./main/alerts');
require('./main/activity-related');
require('./main/popups');
require('./main/sort-field');
require('./main/findstr.hook');
require('./main/findstr-dropdowns');
require('./main/english-alert');
require('./main/pdf-links');
require('./main/activity-listing-filter');
require('./main/activity-items');
require('./main/cmc-didomi-trigger');
require('./main/medias-gallery');
require('./main/header-contact-info');

import { Fancybox } from '@fancyapps/ui';
import AOS from 'aos';
import domReady from '@wordpress/dom-ready';

Fancybox.bind('[data-fancybox]');

domReady(() => {
  AOS.init({
    startEvent: 'load',
    anchorPlacement: 'top-bottom',
    easing: 'ease-in-cubic',
  });
});
