import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

const alertsHTML = sessionStorage.getItem('alertsHTML' + window.theme.lang);
const alertsTime = sessionStorage.getItem('alertsTime' + window.theme.lang);
if (
  !alertsHTML ||
  (alertsTime && 180 < Math.floor(Date.now() / 1000) - alertsTime)
) {
  fetch(window.theme.alerts_path)
    .then((response) => {
      if (response.ok) {
        return response.text();
      }
      return Promise.reject('Error: ' + response.status);
    })
    .then((html) => {
      sessionStorage.setItem('alertsHTML' + window.theme.lang, html);
      sessionStorage.setItem(
        'alertsTime' + window.theme.lang,
        Math.floor(Date.now() / 1000)
      );
      domReady(() => {
        vtxInitAlerts(html);
      });
    })
    .catch(() => {
      domReady(() => {
        vtxCloseAlerts();
      });
    });
} else {
  domReady(() => {
    vtxInitAlerts(alertsHTML);
  });
}

function vtxCloseAlerts() {
  const alertsBar = document.querySelector('#alerts-bar');
  if (alertsBar) {
    alertsBar.classList.remove('visible');
  }
  document.body.classList.remove('has-alerts-bar');
}

function vtxInitAlerts(html) {
  if (!html) {
    return;
  }
  //create html object for manipulation
  const theHTML = document.createElement('div');
  theHTML.innerHTML = html;
  //define the page we are in
  let pageId = '';
  document.body.classList.forEach((element) => {
    if (-1 !== element.trim().indexOf('page-id-')) {
      pageId = element.trim().split('-').slice(-1).pop();
    }
    if (-1 !== element.trim().indexOf('postid-')) {
      pageId = element.trim().split('-').slice(-1).pop();
    }
  });
  const alertsBarWrapper = document.querySelector('.alerts-bar-wrapper');
  if (alertsBarWrapper) {
    let alertsBar = null;
    const theHTMLBars = theHTML.querySelector('#alerts-bar');
    if (theHTMLBars) {
      alertsBarWrapper.innerHTML = theHTMLBars.outerHTML;
      alertsBar = document.querySelector('#alerts-bar');
    }
    if (alertsBar) {
      let alertsBarItems = alertsBar.querySelectorAll('.alert-item');
      const alertBarClosed = sessionStorage.getItem(
        'alertBarClosed' + window.theme.lang
      );
      //First pass through items to eliminate closed alerts and out of bound alerts
      if (alertsBarItems && alertsBarItems.length) {
        alertsBarItems.forEach((item) => {
          if (item.dataset.pages && !pageId) {
            //this alert is restricted but no id of the current page => do not display
            item.remove();
          } else if (
            item.dataset.pages &&
            -1 === item.dataset.pages.split(',').indexOf(pageId)
          ) {
            //page id not there for this alert inside the restricted pages => do not display
            item.remove();
          } else if (
            alertBarClosed &&
            item.dataset.id &&
            -1 !== alertBarClosed.split(',').indexOf(item.dataset.id)
          ) {
            //The id is in the alert closed => do not display
            item.remove();
          }
          //otherwise we let it there, no item.dataset.pages means everywhere
        });
        //The final items
        alertsBarItems = alertsBar.querySelectorAll('.alert-item');
      }
      if (alertsBarItems && alertsBarItems.length) {
        document.body.classList.add('has-alerts-bar');
        const carouselArgs = {
          slidesPerView: 1,
          pagination: {
            el: alertsBar.querySelector('.alerts-bar__pagination'),
            clickable: true,
          },
        };

        if (1 < alertsBarItems.length) {
          carouselArgs.loop = true;
        }

        const alertBarSwiper = new Swiper(
          alertsBar.querySelector('.alerts-bar__carousel'),
          carouselArgs
        );

        alertBarSwiper.on('slideChange', function () {
          const currentSlide = this.slides[this.activeIndex];
          alertsBar.setAttribute(
            'data-current-color',
            currentSlide.getAttribute('data-color')
          );
          alertsBar.setAttribute(
            'data-current-text-color',
            currentSlide.getAttribute('data-text-color')
          );
          alertsBar.style.backgroundColor =
            currentSlide.getAttribute('data-color');
        });
        const alertsCloseBtn = document.querySelectorAll('.alertsBarCloseBtn');
        if (0 < alertsCloseBtn.length) {
          alertsCloseBtn.forEach(function (closeBtn) {
            closeBtn.addEventListener('click', function () {
              alertsBar.classList.remove('visible');
              document.body.classList.remove('has-alerts-bar');
              let allBarsClosed = [];
              if (alertBarClosed) {
                allBarsClosed = alertBarClosed.split(',');
              }
              alertsBarItems.forEach((item) => {
                if (item.dataset.id) {
                  allBarsClosed.push(item.dataset.id);
                }
              });
              sessionStorage.setItem(
                'alertBarClosed' + window.theme.lang,
                allBarsClosed
              );
            });
          });
        }
      } else {
        alertsBarWrapper.innerHTML = '';
      }
    } else {
      alertsBarWrapper.innerHTML = '';
    }
  }
  const alertsModalWrapper = document.querySelector('.alerts-modal-wrapper');
  if (alertsModalWrapper) {
    let alertsModal = null;
    const theHTMLmodals = theHTML.querySelector('#alerts-modal');
    if (theHTMLmodals) {
      alertsModalWrapper.innerHTML = theHTMLmodals.outerHTML;
      alertsModal = document.querySelector('#alerts-modal');
    }
    if (alertsModal) {
      let alertsModalItems = alertsModal.querySelectorAll('.alert-item');
      const alertModalClosed = sessionStorage.getItem(
        'alertModalClosed' + window.theme.lang
      );
      //First pass through items to eliminate closed alerts and out of bound alerts
      if (alertsModalItems && alertsModalItems.length) {
        alertsModalItems.forEach((item) => {
          if (item.dataset.pages && !pageId) {
            //this alert is restricted but no id of the current page => do not display
            item.remove();
          } else if (
            item.dataset.pages &&
            -1 === item.dataset.pages.split(',').indexOf(pageId)
          ) {
            //page id not there for this alert inside the restricted pages => do not display
            item.remove();
          } else if (
            alertModalClosed &&
            item.dataset.id &&
            -1 !== alertModalClosed.split(',').indexOf(item.dataset.id)
          ) {
            //The id is in the alert closed => do not display
            item.remove();
          }
          //otherwise we let it there, no item.dataset.pages means everywhere
        });
        //The final items
        alertsModalItems = alertsModal.querySelectorAll('.alert-item');
      }
      if (alertsModalItems && alertsModalItems.length) {
        const alertsCloseBtn = alertsModalWrapper.querySelectorAll(
          '.alertsModalCloseBtn'
        );
        const carouselArgs = {
          slidesPerView: 1,
          pagination: {
            el: alertsModal.querySelector('.alerts-modal__pagination'),
            clickable: true,
          },
        };

        if (1 < alertsModalItems) {
          carouselArgs.loop = true;
        }

        const alertsModalSwiper = new Swiper(
          alertsModal.querySelector('.alerts-modal__carousel'),
          carouselArgs
        );

        function applyButtonColors(button) {
          if (button) {
            const btnColor = button.getAttribute('data-btn-color');
            const btnTextColor = button.getAttribute('data-btn-text-color');
            const btnHoverColor = button.getAttribute('data-btn-hover-color');
            const btnHoverTextColor = button.getAttribute(
              'data-btn-hover-text-color'
            );

            button.style.backgroundColor = btnColor;
            button.style.color = btnTextColor;

            button.addEventListener('mouseover', () => {
              button.style.backgroundColor = btnHoverColor;
              button.style.color = btnHoverTextColor;
            });

            button.addEventListener('mouseout', () => {
              button.style.backgroundColor = btnColor;
              button.style.color = btnTextColor;
            });
          }
        }

        const initialSlide = alertsModal.querySelector('.swiper-slide-active');
        const initialButton = initialSlide.querySelector('.btn');
        applyButtonColors(initialButton);

        alertsModalSwiper.on('slideChange', function () {
          const currentSlide = this.slides[this.activeIndex];
          alertsModal.setAttribute(
            'data-current-color',
            currentSlide.getAttribute('data-color')
          );
          alertsModal.setAttribute(
            'data-current-text-color',
            currentSlide.getAttribute('data-text-color')
          );
          alertsModal.style.backgroundColor =
            currentSlide.getAttribute('data-color');

          const button = currentSlide.querySelector('.btn');
          applyButtonColors(button);
        });

        if (0 < alertsCloseBtn.length) {
          alertsCloseBtn.forEach(function (closeBtn) {
            const colorValue = closeBtn.getAttribute('data-color');
            const svgPath = closeBtn.querySelector('svg path');
            if (svgPath) {
              svgPath.setAttribute('fill', colorValue);
            }
            closeBtn.addEventListener('click', function () {
              alertsModalWrapper.classList.remove('visible');
              document.documentElement.classList.remove('lockedPosition');
              let allModalsClosed = [];
              if (alertModalClosed) {
                allModalsClosed = alertModalClosed.split(',');
              }
              alertsModalItems.forEach((item) => {
                if (item.dataset.id) {
                  allModalsClosed.push(item.dataset.id);
                }
              });
              sessionStorage.setItem(
                'alertModalClosed' + window.theme.lang,
                allModalsClosed
              );
            });
          });
        }

        alertsModalWrapper.classList.add('visible');
        document.documentElement.classList.add('lockedPosition');
      } else {
        alertsModalWrapper.innerHTML = '';
      }
    } else {
      alertsModalWrapper.innerHTML = '';
    }
  }
}
