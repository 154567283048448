(function ($) {
  let slideBarToggle = null;

  function checkOpenedDropdownsjQuery(nav) {
    const openedDropdowns = nav.find('.subnav-depth-0 .dropdown.opened');

    if (0 < openedDropdowns.length) {
      nav.addClass('has-opened-dropdown');
    } else {
      nav.removeClass('has-opened-dropdown');
    }
  }

  function checkOpenedDropdowns(nav) {
    const openedDropdowns = nav.querySelectorAll(
      '.subnav-depth-0 .dropdown.opened'
    );

    if (0 < openedDropdowns.length) {
      nav.classList.add('has-opened-dropdown');
    } else {
      nav.classList.remove('has-opened-dropdown');
    }
  }

  function toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();

    const $toggle = $(this);
    const $li = $toggle.closest('.dropdown');

    if ($li.hasClass('opened')) {
      $li.removeClass('opened');
      $toggle.attr('aria-expanded', 'false');
    } else {
      $li.addClass('opened');
      $toggle.attr('aria-expanded', 'true');
    }

    setTimeout(() => {
      const mobileMenu = document.querySelector('#mobile-menu');
      const navPrimary = document.querySelector('.nav-primary');
      checkOpenedDropdowns(mobileMenu);
      checkOpenedDropdowns(navPrimary);
      mobileMenu.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }, 250);
  }

  function prepareDropdownForNav($navWrapper, $nav) {
    ///// INIT SLIDER POSITION /////
    $nav.find('.subnav-depth-0 > .active').addClass('opened');

    setTimeout(() => {
      checkOpenedDropdownsjQuery($navWrapper);
    }, 250);

    ///// INIT EVENTS /////
    $nav.find('.dropdown__toggle').on('click', toggleDropdown);
    $nav.find('.dropdown__subnav__main-back').on('click', toggleDropdown);
  }

  function closeSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', false);
    $('body').removeClass('mobile-menu-open');
    $(document).off('click.close_slidebar').off('keyup.close_slidebar');
  }

  function tryClosingSlidebarMenu(event) {
    // Keyboard
    if ('undefined' !== typeof event.keyCode) {
      // ESCAPE
      if (27 === event.keyCode) {
        $('.vtx-burger-container').focus();
        closeSlidebarMenu();
      }
    }
    // Click
    else if (!$(event.target).closest('#mobile-menu-wrapper').length) {
      closeSlidebarMenu();
    }
  }

  function openSlidebarMenu() {
    slideBarToggle.attr('aria-expanded', true);
    $('body').addClass('mobile-menu-open');
    $(document)
      .on('click.close_slidebar', tryClosingSlidebarMenu)
      .on('keyup.close_slidebar', tryClosingSlidebarMenu);
  }

  function initMenuMobile() {
    slideBarToggle = $('.vtx-burger-container');

    const $mobileMenu = $('#mobile-menu');

    slideBarToggle.on('click', function (event) {
      event.stopPropagation();

      if ($('body').hasClass('mobile-menu-open')) {
        closeSlidebarMenu();
      } else {
        openSlidebarMenu();
      }
    });

    prepareDropdownForNav($mobileMenu, $mobileMenu.find('.nav-mobile'));
    prepareDropdownForNav($('.nav-primary'), $('.nav-primary'));
  }

  $(document).ready(function () {
    initMenuMobile();
  });
})(jQuery);
