import domReady from '@wordpress/dom-ready';
import * as Focus from '../common/focus';

domReady(() => {
  const body = document.getElementsByTagName('body')[0];

  function openPopup(popupID) {
    const allPopups = document.querySelectorAll('.popupWindow');
    allPopups.forEach((popup) => {
      const currentID = popup.getAttribute('data-popup-id');
      const openBtnCurrentPopup = document.querySelector(
        '.openPopupWindow[data-popup-id="' + currentID + '"'
      );
      if (currentID === popupID) {
        popup.classList.add('opened');
        popup.setAttribute('aria-hidden', 'false');
        openBtnCurrentPopup.setAttribute('aria-expanded', 'true');
        document.documentElement.classList.add('lockedPosition');
        Focus.trapFocusInside(jQuery('#' + popupID));
      } else if (popup.classList.contains('opened')) {
        closePopup(popup);
      }
    });
  }

  function closePopup(popup) {
    const currentID = popup.getAttribute('data-popup-id');
    const openBtnCurrentPopup = document.querySelector(
      '.openPopupWindow[data-popup-id="' + currentID + '"'
    );
    popup.classList.remove('opened');
    popup.setAttribute('aria-hidden', 'true');
    openBtnCurrentPopup.setAttribute('aria-expanded', 'false');
    document.documentElement.classList.remove('lockedPosition');
  }

  body.addEventListener('click', function (event) {
    const openingBtn = event.target.closest('.openPopupWindow');
    const closingBtn = event.target.closest('.closePopupWindow');
    let popupID = null;
    let popup = null;

    if (openingBtn) {
      popupID = openingBtn.getAttribute('data-popup-id');
      openPopup(popupID);
    } else if (closingBtn) {
      popup = closingBtn.closest('.popupWindow');
      closePopup(popup);
    } else if (event.target.classList.contains('popupWindow')) {
      popup = event.target;
      closePopup(popup);
    }
  });
});
