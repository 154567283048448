import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const carousels = document.querySelectorAll('.popular-requests-carousel');

  if (0 < carousels.length) {
    carousels.forEach(function (carousel) {
      new Swiper(carousel, {
        slidesPerView: 'auto',
        watchOverflow: true,
        spaceBetween: 20,
        breakpoints: {
          480: {
            spaceBetween: 30,
          },
        },
      });
    });
  }
});
