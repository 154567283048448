import * as Focus from '../common/focus';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const toggleSearch = document.querySelectorAll('.toggleSearch');
  const searchModal = document.querySelector('#search-modal');
  const closeSearch = document.querySelector('#search-close');

  function closeSearchModal() {
    searchModal.classList.remove('opened');
    closeSearch.setAttribute('aria-expanded', 'false');
    document.documentElement.classList.remove('lockedPosition');
    toggleSearch.forEach(function (btn) {
      btn.setAttribute('aria-expanded', 'false');
    });

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.untrapFocusInside(jQuery('#search-modal'));
    }
  }

  function openSearchModal() {
    const openEvent = new Event('opensearchmodal');
    searchModal.classList.add('opened');
    closeSearch.setAttribute('aria-expanded', 'true');
    window.dispatchEvent(openEvent);
    document.documentElement.classList.add('lockedPosition');
    toggleSearch.forEach(function (btn) {
      btn.setAttribute('aria-expanded', 'true');
    });

    setTimeout(function () {
      document.getElementById('findstr_main_search_input').focus();
    }, 200);

    if (searchModal.classList.contains('search-modal--window')) {
      Focus.trapFocusInside(jQuery('#search-modal'));
    }
  }

  if (0 < toggleSearch.length && closeSearch) {
    toggleSearch.forEach(function (btn) {
      btn.addEventListener('click', function () {
        if (searchModal.classList.contains('opened')) {
          closeSearchModal();
        } else {
          openSearchModal();
        }
      });
    });

    closeSearch.addEventListener('click', function () {
      if (searchModal.classList.contains('opened')) {
        closeSearchModal();
      }
    });
  }
});

document.addEventListener('findstrLoaded', () => {
  const findstr = window.findstr;
  const modalForm = document.querySelector('#search-modal .search-form');

  if (findstr && modalForm) {
    findstr.hooks.addAction('searchResults', 'ithq', (results, group) => {
      if ('search-autocomplete' === group) {
        if (0 === results.totalHits || '' === results.query) {
          modalForm.classList.remove('dropdown-active');
        } else {
          modalForm.classList.add('dropdown-active');
        }
      }
    });
  }
});
