import domReady from '@wordpress/dom-ready';

domReady(() => {
  const englishAlert = document.querySelector('#english-alert');
  const englishAlertCloseBtn = document.querySelector('#english-alert button');

  if (englishAlert) {
    document.body.classList.add('has-english-bar');
  }

  if (englishAlertCloseBtn) {
    englishAlertCloseBtn.addEventListener('click', function () {
      englishAlert.classList.remove('visible');
      document.cookie = 'remove_english_alert=true';
      document.body.classList.remove('has-english-bar');
    });
  }
});
