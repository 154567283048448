import domReady from '@wordpress/dom-ready';

domReady(() => {
  const buttons = document.querySelectorAll('.wp-block-button__link');

  if (0 < buttons.length) {
    buttons.forEach(function (button) {
      const btnText = button.textContent;
      button.textContent = '';
      const firstArrow = document.createElement('i');
      firstArrow.className = 'vtx-icon vtx-icon-down_arrow first-arrow';
      const secondArrow = document.createElement('i');
      secondArrow.className = 'vtx-icon vtx-icon-down_arrow second-arrow';
      const span = document.createElement('span');
      span.innerHTML = btnText;
      button.prepend(firstArrow);
      button.appendChild(span);
      button.appendChild(secondArrow);
    });
  }
});
