import Swiper from 'swiper';
import domReady from '@wordpress/dom-ready';

domReady(() => {
  const promoCarousel = document.querySelector('.promo-header__carousel');

  if (promoCarousel) {
    const slides = promoCarousel.querySelectorAll('.swiper-slide');

    if (slides.length > 1) {
      new Swiper(promoCarousel, {
        slidesPerView: 1,
        loop: true,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: promoCarousel.querySelector('.swiper-pagination'),
          clickable: true,
        },
      });
    }
  }
});
