import domReady from '@wordpress/dom-ready';

domReady(() => {
  const links = document.querySelectorAll(
    '.entry-content p > a[href$=".pdf"], .entry-content li > a[href$=".pdf"], .entry-content em > a[href$=".pdf"], .entry-content tr > td > a[href$=".pdf"], .entry-content strong > a[href$=".pdf"]'
  );
  const svg = document.querySelector('#pdf-document-svg svg');

  if (0 < links.length) {
    links.forEach((link) => {
      link.classList.add('pdf-link-initialized');
      const text = link.textContent;
      link.textContent = '';
      const label = document.createElement('span');
      label.classList.add('label');
      label.textContent = text;
      const svgClone = svg.cloneNode(true);
      link.appendChild(label);
      link.appendChild(svgClone);
    });
  }
});
