import domReady from '@wordpress/dom-ready';

domReady(() => {
  //modify permalink on activity items on loading for formation and workshops
  const activityItems = document.querySelectorAll('.activity-item');

  if (activityItems) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cityParam = 'findstr[filter][workshop_cities][value][]';
    const formationCityParam = 'findstr[filter][formation_cities][value][]';

    // Récupérer les valeurs des ateliers
    const workshopCities = urlParams.getAll(cityParam);

    if (0 !== workshopCities.length) {
      // Créer un nouveau modèle d'URL avec les valeurs actuelles
      const newQueryString = workshopCities
        .map((city) => `${cityParam}=${encodeURIComponent(city)}`)
        .join('&');
      activityItems.forEach((activityItem) => {
        const currentHref = activityItem.children[0].attributes.href.value;
        const updatedHref = currentHref.split('?')[0] + '?' + newQueryString;
        activityItem.children[0].attributes.href.value = updatedHref;
      });
    }

    const formationCities = urlParams.getAll(formationCityParam);

    if (0 !== formationCities.length) {
      // Créer un nouveau modèle d'URL avec les valeurs actuelles
      const newQueryString = formationCities
        .map((city) => `${formationCityParam}=${encodeURIComponent(city)}`)
        .join('&');
      activityItems.forEach((activityItem) => {
        const currentHref = activityItem.children[0].attributes.href.value;
        const updatedHref = currentHref.split('?')[0] + '?' + newQueryString;
        activityItem.children[0].attributes.href.value = updatedHref;
      });
    }
  }
});
