window.addEventListener('DOMContentLoaded', function () {
  const tooltips = document.querySelectorAll('.tooltip[data-text]');

  if (0 < tooltips.length) {
    tooltips.forEach(function (tooltip) {
      const text = tooltip.getAttribute('data-text');
      const tooltipDiv = document.createElement('div');
      const textNode = document.createTextNode(text);
      tooltipDiv.appendChild(textNode);
      tooltip.appendChild(tooltipDiv);
    });
  }
});
