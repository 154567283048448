import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';

domReady(() => {
  const carousels = document.querySelectorAll('.activity-related__carousel');

  if (0 < carousels.length) {
    carousels.forEach((carousel) => {
      new Swiper(carousel, {
        slidesPerView: 1.3,
        spaceBetween: 24,
        loop: false,
        watchOverflow: true,

        scrollbar: {
          el: carousel.querySelector('.swiper-scrollbar'),
          draggable: true,
        },

        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          540: {
            slidesPerView: 2.4,
          },
          920: {
            slidesPerView: 3.3,
          },
          1280: {
            slidesPerView: 4.3,
          },
          1400: {
            slidesPerView: 5.05,
          },
        },
      });
    });
  }
});
