let fieldsAreInitialized = false;

function enableReadOnly() {
  const fields = document.querySelectorAll(
    '.gf_readonly input, .gf_readonly textarea'
  );
  if (0 < fields.length) {
    fields.forEach(function (field) {
      field.setAttribute('readonly', '');
    });
  }
}

function changeFileUpload() {
  const multiFileUploads = document.querySelectorAll(
    '.gform_fileupload_multifile'
  );
  if (0 < multiFileUploads.length) {
    multiFileUploads.forEach(function (fileUpload) {
      fileUpload.querySelector('.gform_drop_instructions').textContent =
        wp.i18n._x(
          'Drag and drop file to upload',
          'drag and drop instruction',
          'vtx'
        );
    });
  }
}

document.addEventListener('gform_post_render', function () {
  fieldsAreInitialized = true;
  changeFileUpload();
  enableReadOnly();
});

// ON READY
window.addEventListener('load', function () {
  if (!fieldsAreInitialized) {
    changeFileUpload();
    enableReadOnly();
  }
});
