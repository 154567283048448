import domReady from '@wordpress/dom-ready';

domReady(() => {
  const contentDiv = document.querySelector(
    '.contact-header__phone-wrapper__content'
  );
  const phonePattern =
    /(\+?1[-.\s]?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}|\d{3}\s\d{3}-\d{4})/g;

  if (contentDiv) {
    let content = contentDiv.innerHTML;
    content = content.replace(phonePattern, function (match) {
      // Vérifie si le numéro de téléphone est déjà dans un lien tel:
      const linkPattern = new RegExp(
        `<a\\s+href=["']tel:${match.replace(
          /[-.\s]/g,
          ''
        )}["'][^>]*>[^<]*<\/a>`,
        'i'
      );
      if (!linkPattern.test(content)) {
        return `<a href="tel:${match.replace(/[-.\s]/g, '')}">${match}</a>`;
      }
      return match;
    });
    contentDiv.innerHTML = content;
  }
});
