import domReady from '@wordpress/dom-ready';

domReady(() => {
  const triggers = document.querySelectorAll('.nav-item.cmc-didomi-trigger');

  if (0 < triggers.length && window.Didomi) {
    triggers.forEach((triggerItem) => {
      const linkWrapper = triggerItem.firstChild;
      const link = linkWrapper.querySelector('.nav-item__link');

      if (link) {
        link.addEventListener('click', function (e) {
          e.preventDefault();
          window.Didomi.preferences.show();
        });
      }
    });
  }
});
